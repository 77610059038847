.list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.list-office-item {
	line-height: 1.7;
	margin-bottom: 10px;

	@include media-max($phone-wide) {
		margin-left: 28%;
		padding-left: 20px;
	}

	@include media-max($phone-landscape) {
		margin-left: 32%;
		
	}
	
	
}

.list-office-phone {
	display: block;
}