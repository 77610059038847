.tab-slides-item {
	position: absolute;
	left: 0;
	top: 100%;
	width: 100%;
	display: none;


	@include media-max($phone-wide) {
		position: static;
		top: auto !important;
		display: block !important;
	}


	&.js-tab-slide-active {
		position: relative;
		top: 0;
		display: block;

		@include media-max($phone-wide) {
			position: static;
		}
	}
  	.content-block{
	  margin-bottom:$spacing-unit;
	}
}

.js-tab-slides-wrapper {
	position: relative;
	overflow: hidden;
}

.experience-achievements {
	.list {
		margin-bottom: $spacing-unit;
	}
}