.form-basic {

}

.form-field {
	margin-bottom: $spacing-unit/2;
	position: relative;

	input {
		background-color: $opaquegrey;
		color: $color-text;
		&[type=text]{
		  cursor:default;//insert cursor disappears on gray background
		}
	}
}

.search-button {
	position: absolute;
	right: 15px;
	top: 13px;
	font-size: 1.3em;
}

input,
textarea {
	border: 0 none;
	padding: 15px;
	line-height: 1.1;
	width: 100%;
	font-weight: $font-weight-base;


	@include placeholder {
		color: $color-text;
	}
}
//used for CCPA Form
.form-input-wide, .form-input-group {	
	margin-top: 20px;
}

form .form-input-wide input[type=email], .form-input-group, .form-input-wide  .standard-textbox {
	background-color: #ebebeb;
	border: #aeaeae;
	width: 94%;

	
}

.inline-other-textbox {
	background-color: #fff;
	border: #fff;
	display: inline;
	width: 60%;
}

.form-input-group {
	width: 94%;
	margin-top: 20px;
	padding-bottom: 20px;
	padding-top: 20px;

	label{
		width: 94%;
	}

	input[type="radio"], 
	input[type="checkbox"] {
		text-align: left;
		vertical-align: middle;
		width: 10%;
	}

	.question-label {
		margin-left: 15px;
	}

	
}

