.social-links {

	.icon  {
		margin-left: 5px;
		font-size: 22px;
		line-height: 1.1;

		&:first-child {
			margin-left: 7px;
		}
	}

	transition: transform .2s ease-in-out;

	&.is-expanded {
			transform: translateX(-38px);
	}
}

.social-links-trigger {
	background-color:$bg-social-inpage;
	color:$color-dark-blue;
	font-weight: $font-weight-medium;
	position: absolute;
	left: -5px;
	top: 0;
	padding: 9px 10px 10px 10px;
	transform: translate(35px, 0)
				rotate(90deg);
	white-space: nowrap;
	font-size: 1.2em;
	transform-origin: 0 0;
	

	&:after {
		content: '+';
		display: inline-block;
		width: 12px;
		margin-left: 3px;

		.is-expanded & {
			content: '-';
		}
	}

}


.social-links-inpage {
	position: fixed;
	right: 0;
	bottom: 15%;
	z-index: 10;
	width: 30px;

	@include media-max($phablet) {
		display: none;
	}

	.icon {
		color: $color-brand-white;
		font-size: 20px;

		&:hover {
			color: $color-text;
		}
		
	}
}

.social-links-wrapper {
	background-color:$bg-social-inpage;
	transform: translateX(100%);
	width: 38px;
    left:-8px;
    position: relative;
	padding: 10px 0;
	text-align: center;
	transition: transform .2s linear;
}

.social-link-pdf {
	background-color:$bg-social-inpage;
    position: fixed;
    bottom: 15%;
    margin-bottom: -52px;
    right: 0;
    width:38px;
    padding: 10px 0;
    z-index: 10;
    text-align: center;
    	.icon  {
		margin-left: 5px;
		font-size: 22px;
		line-height: 1.1;
		color: $color-brand-white;
		font-size: 20px;

		&:hover {
			color: $color-text;
		}
	}
}