.bio-card {
	@extend %clearfix;

	position: relative;

	h1 {
		@extend .hero-size-interior;

		margin-top: 0;
	}
	
	h1,.h3-size {
		margin: 5px 0;
	}

	.social-links {
		margin: 10px 0;
		a{
		  color:$color-brand-black;
		  &:hover{
			color:$blue;
		  }
		  &:first-child{
			margin-left:0;
		  }
		}
		.icon-google-plus {
			font-size: 18px;
		}
	}

	.media-img {
		
		display: none;

		@include media-max($header-nav-bp) {
			display: block;
			overflow: hidden;

			> img {
				width: 100%;
				max-width: none;
				position: relative;
			}
		}

		.no-photo {
			background-color: $grey-lightest;
			color: $color-brand-white;
			height: 100%;
			max-width: none;
			position: relative;
			font-size: 1em;
			padding-left: 25%;
			padding-top: 20%;
			padding-bottom: 20%;
		}

		@include media-max($phone-wide) {
			width: 28%;
		}

		@include media-max($phone-landscape) {
			width: 32%;
		}

	}

	.media-content {
		
		@include media-max($header-nav-bp) {
			width: 75%;
			padding-left: 20px;
		}

		@include media-max($phone-wide) {
			width: 70%;
		}

		@include media-max($phone-landscape) {
			width: 65%;
			
		}
	}
}
.bio-card-title {
	line-height: 1;
	margin-top: 10px;
}

.bio-card-snapshot{
	position: relative;

	@include media-max($header-nav-bp) {
		display: none;
	}

	> img  {
		position: fixed;
		top: 140px;
		z-index: 1;
		width: 35%;
		left: 0;

		@include media-min($large-screen) {
			width: 28%;
		}
	}

	.no-photo {
		background-color: $grey-lightest;
		color: $color-brand-white;
		width: 28%;
		height: 100%;
		display: block;
		position: fixed;
		top: 140px;
		left: 0;
		font-size: 4em;
		padding-left: 5%;
		padding-top: 10%;
	}
}