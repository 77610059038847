.news-detail {

	.article-hero {
		img {
			width: 100%;
			margin-bottom: 20px;
			display: block;
		}
	}

	.article-title {
		font-size: 1.5em;
		font-weight: 300;
		color: $color-dark-blue;
	}

	.article-when,
	.article-where {
		padding-bottom: 15px;
	}

	.section-header {
		text-transform: uppercase;
		color: $maroon;
	}

	.article-authors {
		font-weight: 300;
		padding-bottom: 15px;
	}

	.article-summary {
		text-transform: uppercase;
		color: $orange;
	}

	.site-hero-title {
		margin-top: 20px;
	}

	.main-content {
		padding-right: 30px;
		border-right: 1px solid black;
	}

	.secondary-content {
		padding-left: 30px;
	}
	.main-content,
	.secondary-content {
		@media all and (max-width: $tablet) {
			padding: 0;
			width: 100%;
			border-right: none;
		}
	}


	.download-link {
		font-size: 2em;
		font-weight: 100;
	}

	.primary-related {
		float: left;
	}

	.grid-content-sidebar {
		float: right;
	}

	.related-item {
		margin-bottom: 10px;
	}

	.featured-practices  {
		margin: $block-spacing auto ;
	}

	.practice-highlight-item {
		width: 100%;
		float: none;
	}
	.three-articles{
	  width:100%;
	  .article{
		width:32.33333%;
		margin-right:1%;

		@media all and (max-width: $tablet-landscape) {
			width: 45%;
			margin-bottom: 20px;
			height:auto;
		}

		@media all and (max-width: $tablet-alt) {
			width: 100%;
		}
	  }
	}
}

@media print {
        .news-detail {
              width: 80%;
              font-size: 20px; }
    }