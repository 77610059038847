.location-detail {
	.location-content-wrapper {
		float: right;

		@media all and (max-width: $tablet) {
			float: none;
		}
	}

	.grid-content-sidebar {
		padding-left: 20px;
			@media all and (max-width: $tablet) {
				padding-left: 0;
			}
	}
	.fancy-items-highlights{
	  padding-top:0;
	}
	.office-image {
		width: 100%;
	}

	.office-information {
		overflow: hidden;
	  	font-size: 14px;
	  .phone {
		&:before {
		  content: 'T';
		  margin-right: 5px;
		  font-weight:bold;
		}
	  }
	  .fax {
		&:before {
		  content: 'F';
		  margin-right: 5px;
		  font-weight:bold;
		}
	  }
	  &+a{
		margin-top:20px;
		display:inline-block;
	  }
	}

	@media all and (max-width: $tablet) {
		.grid-col-50 {
			float: none;
			width: 100%;
		}
	}

	.office-map {
        margin-top: 25px;
		padding-bottom: 75%; // This is the aspect ratio
		height: 0;
		overflow: hidden;
	  	position:relative;
        > img {
		    width: 100%;
        }
		iframe{
		  position: absolute;
		  top: 0;
		  left: 0;
		  width: 100% !important;
		  height: 100% !important;
		}
	}

    .office-contacts {
        margin-top: 25px;
		.office-contact{
		  display:inline-block;
		  float:left;
		  margin-right:20px;
		  &:last-child{
			float:none;
			:after {
			  clear: both;
			}
		  }
		}
    }
	.all-people-link {
	  margin-top: 20px;
	  font-weight: 300;
	  font-size: 1.2em;
	  @media (max-width:$phablet){
		margin-bottom:20px;
	  }
	}

}