.resource-landing-page {

   .calls-to-action {
		display: flex;
		justify-content: space-between;
	}

	.cta {
		background-color: #31A1D2;
		border: none;
		text-align: center;
		text-decoration: none;
		display: inline-block;
		font-size: 1.4em;
		line-height: 1.4;
		width: 40%;
		padding: 15px 10px 15px 10px;
		margin-top: 5px;

		@include media-max($phone-wide) {
			width: 100%;
			font-size: 1.2em;
		}

		.action-text {
			color: black;
		}

		.resource-title {
			color: white;
		}

		span {
			text-transform: uppercase;
			display: block;
		}
	}

}