@media print {
  * {
    text-shadow: none !important;
    color: #000 !important;
    background: transparent !important;
    box-shadow: none !important;
  }
  body{
    width: 80%;
    font-size: 20px; 
    margin:0;
    padding:0;
    background-size:0;
  }
  pre,
  blockquote {

    page-break-inside: avoid; }

  thead {
    display: table-header-group; }

  tr,
  img {
    page-break-inside: avoid; }

  img {
    max-width: 100% !important;
  }

  .site-main img  {
    width: auto !important;
  }

  p,
  h1,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  .global-wrapper{
    padding-top: 20px;
  }
  .global-header{
    position:relative;
    .print-logo{
      display:block;
    }
  }

  select {
    background: #fff !important; }

  .navbar {
    display: none; }

  .table td,
  .table th {
    background-color: #fff !important; }

  .btn > .caret,
  .dropup > .btn > .caret {
    border-top-color: #000 !important; }

  .label {
    border: 1px solid #000; }

  .table {
    border-collapse: collapse !important; }

  header .site-nav,
  header button,
  .news-search,
  .viewMore,
  .people-search,
  .people-landing .grid-content-small,
  .industry .grid-content-small-quarter,
  .news-detail .grid-content-small-quarter,
   .select-dropdown,
  .person-secondary-menu,
  .social-links,
  .mobile-nav-wrapper,
  footer,
  .tabs-mod,
  .section--tabs,
  .btn-accordion,
  .nav-regions,
  .pdf-link,
  .section-nav
  .prof-overview .contact-list,
  .readmore-link,
  .language-pdf,
  .loading-image,
  .insight .mod.mod-accordion.js-mod-accordion.pros,
  .insight .rel-articles,
  .view-all-trigger,
  .section--related .rel-articles,
  .more-btn-wrapper,
  .download,
  .subscribe-list,
  .load-more,
  .btn,
  .tile-grid-freeflow,
  .nav-content-grid-widgets,
  .content-grid-widgets,
  .content-grid-main .article-details,
  .image-carousel,
  .video-carousel,
  .bio-card-snapshot,
  .card-back,
  .mini-banner,
  .grid-output,
  .accordion-hide-content h1 .fa-angle-up, .accordion-hide-content h1 .fa-angle-down,
  .read-more:after, .pdf-link:after, .exp-listing__link:after, .box li a:after, .box__abstract p a:after{
    display: none !important;
  }

  .content-block + .content-block{
    padding-top:20px;
  }
  .show-more-hide{
    display:inline-block !important;
  }


  span.label{
    border: none;
  }

  .printlogo {
    display: block;
    width: 250px;
  }

  .site-banner {
    padding-left: 0;

    .page-title {
      padding: 0;
    }
  }

  //tabs
  .tab-slides-item{
    display:inline-block !important;
    position:relative !important;
    top:0 !important;
  }
  //people
  .people-detail .content-wrap-left{
    padding-left:0;
  }
  .card-front{
    border:none !important;
  }
  .practice-highlight-item{
    display: none !important;
  }
  .article-highlight-item:last-child{
    margin-bottom:20px !important;
  }

  //industry
  .industry{
    .grid-content-large-75.fr{
      float:left;
    }
    .grid-content-med-half{
      width:100%;
      padding-right:40px;
    }
  }

  //news-detail
  .news-detail{
    padding-left:20px;
    padding-right:30px;
    .article{
      width:28% !important;
    }
    .content-wrap-full{
      width:100% !important;
    }
    .grid-content-small{
      width:95%;
      padding-left:0px !important;
      &.fr{
        float:left !important;
      }
    }
    &.client-achievement-detail{
      .grid-content-small{
        width:95%;
        padding-left:0px !important;
        &.fr{
          float:left !important;
        }
      }
    }

  }

  //careers
  .careers-detail, .about-diversity{
    .grid-content-small-quarter.fl{
      display:none !important;
    }
    .grid-content-small.fl{
      display:none !important;
    }
    .grid-content-large-75.fr,.grid-content-large.fr{
      float:left;
    }
    .grid-content-small-quarter{
      display:none !important;
    }
    .grid-content-med-half{
      width:100%;
    }
    .brg{
      border:none;
    }
    .article{
      width:28% !important;
    }
  }

  //location-detail
  .location-detail{
    .grid-content-small.fr{
      display:none !important;
    }
  }
}