[data-flip-card] {
	.card {
		perspective: 800px;
		position: relative;
		font-size: 15px;

		.container {
			position: relative;
			width: 100%;
			height: 100%;
			transform-style: preserve-3d;
			transition: 0.5s;
		}

		.disabled {
			transform-style: initial;
		}

		.container.flipped {
			.card-front {
				transform: rotatey(180deg);
			}

			.card-back {
				transform: rotatey(0deg);
			}
		}

		.no-photo {
			margin: 20px auto 0;
			text-align: center;
		}

		.card-front,
		.card-back {
			width: 100%;
			height: 100%;
			position: absolute;
			backface-visibility: hidden;
			transform-style: preserve-3d;
			transition: 0.5s;

			overflow: hidden;
			cursor: pointer;
			border-bottom: 1px solid $color-brand-black;

			a {
				&.flip-card {
					font-size: 26px;
					float: right;
					position: absolute;
				}
			}
		}

		.card-front {
			z-index: 2;
			background: white;
			height: 100%;
			transform: rotateY(0deg);

			img {
				width: 100%;
				display: block;
			}

			.flip-card {
				bottom: 10px;
				right: 20px;
			}
		}

		.card-back {
			transform: rotatey(-180deg);
			color: white;
			padding: 16px;
			background-color: $color-brand-blue;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			a {
				color: #fff;

			}

			.flip-card {
				bottom: 10px;
				right: 20px;
			}

			.icon-spinner {
				color: $color-brand-white;
			}

			.office {
				line-height: 14px;
			}

			.phone {
				margin-bottom: 6px;
			}
		}

		.hidden-background {
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
			z-index: 1;
		}

		.background-cover {
			position: absolute;
			z-index: 2;
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
			background: rgba($blue, .7);
		}

		.card-content {
			position: relative;
			z-index: 3;
		}
	}

	.disabled {
		perspective: initial !important;
		position: static !important;
	}

	.icon-spinner {
		position: absolute;
		bottom: 10px;
		right: 10px;
		color: $color-brand-blue;
	}
}

.professional-card {
	border: 1px solid $color-brand-black;

	.name,
	.role {
		padding: 0 10px;
		line-height: 12px;
	}
}

.grid-content-tab-slide {
	.practice-highlight-item {
		width: 100%;
		margin-right: 0;

		.card-front {
			border: 1px solid $border-color;
		}
	}
}

div.practice-highlight-item {
	.card-front {
		border: 1px solid $border-color;

	}

	img {
		height: 100%;
	}

	.practice-title {
		transform: translate(0, 0);
		width: 95%;
		padding: 10px;
		top: 0;
		left: 0;
		background-color: rgba(black, .6);
		color: $color-brand-yellow;
		font-weight: 800;
		font-size: 1.2em;
	}
}

.featured-practices {

	@media all and (max-width: $desktop-screen) {
		.grid-col-33 {
			width: 48%;
		}
	}

	@media all and (max-width: $phablet) {
		.grid-col-33 {
			width: 100%;
		}
	}
}

//ie9 (with modernizer)
.no-csstransforms3d {
	[data-flip-card] {
		.card {
			.container.flipped {
				.card-front {
					display: none;
				}

				.card-back {
					display: block;
				}
			}

			.card-front {
				display: block;
			}

			.card-back {
				display: none;
			}
		}
	}
}

.detail-content-practices {
	.card {
		height: 100%;
	}
}