// investigate for late http://jsfiddle.net/2Kwsc/295/
// alternate solution: http://blogs.msdn.com/b/kurlak/archive/2013/11/03/hiding-vertical-scrollbars-with-pure-css-in-chrome-ie-6-firefox-opera-and-safari.aspx
html {
  overflow-x: hidden;
}

.fancy-slides {
  @include media-max($home-tablet) {
    padding-top: 0;
  }
}

.fancy-item {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  color: $color-brand-white;
  padding-top: $site-header-height;
  font-weight: $font-weight-regular;
  overflow: hidden;
  visibility: hidden;
  background-size: 0;

  @include media-max($home-tablet) {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    visibility: visible;
    padding: 25px 0;
    background-size: cover;
  }

  &.fancy-item-centered {
    header {
      text-align: center;
    }
  }

  //title
  header {
    @extend %enormous-size;
    margin: 0 auto;
    max-width: $site-max-width;
    padding: $site-content-padding $site-content-padding 20px;
    position: relative;
    z-index: 5;

    @include media-max(1024px) {
      font-size: 5em;
      padding-bottom: 0;
    }

    @include media-max($home-large) {
      font-size: 5em;
    }

    @include media-max($home-tablet) {
      font-size: 4em;
      padding: 30px 20px 25px;
    }

    @media screen and (max-height: 800px) {
      padding: 30px $site-content-padding 20px;
    }

    @include media-max($home-mobile) {
      font-size: 3em;
      padding: 10px 20px;
    }

    span.blue {
      color: $color-brand-blue;
    }

    span.black {
      color: $black;
    }

    span.white {
      color: $white;
    }
  }

  &.animateOut {
    header {
      animation-name: zoomOutHeading;
    }
  }

  &.animateIn {
    header {
      animation-name: zoomInHeading;
    }
  }
}

.fancy-item {

  &.js-is-active {
    visibility: visible;
    z-index: 6;
  }
}

.fancy-item-compact {

  .fancy-item-content,
  header {
    max-width: 1000px
  }

  header {
    padding-bottom: 50px;

    @media screen and (max-height: 800px) {
      padding-bottom: 25px;
    }

    @include media-max($home-mobile) {
      padding-bottom: 10px;
    }
  }
}

.fancy-item-article {
  width: 40%;

  @include media-max($header-nav-bp) {
    width: 50%;
  }

  @include media-max($home-mobile) {
    width: 100%;
  }
}

.fancy-item-article-title,
.fancy-item-article h2 {
  @extend .h3-size;
  @extend %uc;

  line-height: 1.3;
  margin: 0 0 10px;
  color: $color-text-lead;
  font-weight: $font-weight-light;
}

.fancy-item-content {
  margin: 0 auto;
  max-width: $site-max-width;
  padding: 20px $site-content-padding 0;
  position: relative;
  z-index: 5;
  line-height: 1.6;

  .read-more {
    clear: both;
  }

  .fancy-item-centered & {
    text-align: center;

    .grid-col-50 {
      display: inline-block;
      float: none;
      width: auto;
      padding-right: 15px;
      max-width: 50%;
      vertical-align: top;
      text-align: left;

      @include media-max($home-tablet) {
        max-width: 100%;
        margin-bottom: 10px;
      }
    }
  }

  @include media-max($home-tablet) {
    //padding:20px 20px 0;

    .fancy-item-centered & {
      text-align: left;
    }
  }


  // @media screen and (max-height: 800px) {

  // }


  .animateOut & {
    animation-name: displaceContent;
  }

  .animateIn & {
    animation-name: placeContent;
  }
}

.fancy-slide-img {
  @extend %horizontal-align;
  z-index: 1;
  bottom: 0px;
  transform-origin: -50% 50%;
  display: none;

  @include media-min($home-tablet) {
    display: block;
  }

  .animateOut & {
    animation-name: zoomOutImg;
  }

  .animateIn & {
    transform-origin: -50% 100%;
    animation-name: zoomInImg;
  }
}

//arrow

.fancy-slide-trigger {
  @extend %horizontal-align;

  position: fixed;
  bottom: 170px;
  z-index: 15;
  color: $color-brand-white;
  font-size: 40px;

  @include media-max($home-tablet) {
    display: none;
    //left: 0;
    //bottom: 0;
    //padding: 5px 0;
    //width: 100%;
    //text-align: center;
    //background-color: $bg-site-footer;
    //transform: translateX(0);
    //font-size: 30px;
  }
}

//Navigation
$fancy-box-width: 8px;

.fancy-slides-nav {
  @extend %vertical-align;
  position: fixed;
  padding-top: 20px;
  right: 5%;
  z-index: 15;


  @include media-max($home-tablet) {
    display: none;
  }

}

.fancy-slides-nav-btn {
  display: block;
  background-color: $color-brand-white;
  width: $fancy-box-width;
  height: $fancy-box-width;
  border-radius: $fancy-box-width/2;
  opacity: .5;
  margin-top: 10px;
  transition: opacity .35s ease-in;

  &.is-active,
  &:hover {
    opacity: 1;
  }
}



//ANIMATIONS

.animated {
  backface-visibility: hidden;

  .animateIn & {
    animation-duration: .75s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
  }

  .animateOut & {
    animation-duration: 1.25s;
    //animation-fill-mode: both;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(.44, .01, 1, .15) //cubic-bezier(.49,.01,.99,.19);
  }
}

// .animateIn {
// 	animation-timing-function: cubic-bezier(.49,.01,.99,.29)
// }


//
@keyframes zoomInDown {
  0% {
    opacity: 0;
    transform: scale3d(.1, .1, .1) translate3d(0, -100%, 0);
    animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
  }

  60% {
    opacity: 1;
    transform: scale3d(.475, .475, .475) translate3d(0, -60%, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
  }
}

.zoomInDown {
  animation-name: zoomInDown;
}

//Image Animation
@keyframes zoomOutImg {
  0% {
    opacity: 1;

  }

  100% {
    opacity: 0;
    transform: scale(.5, .5);
  }
}

.zoomOutImg {
  animation-name: zoomOutText;
}

@keyframes zoomInImg {
  0% {
    opacity: 0;
    transform: scale(.5, .5);
  }

  100% {
    opacity: 1;
  }
}

.zoomInImg {
  animation-name: zoomIn;
}


//Heading ANimation
@keyframes zoomInHeading {
  0% {
    opacity: 0;
    transform: scale(.3, .3) translateX(-50%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.zoomInHeading {
  animation-name: zoomIn;
}

@keyframes zoomOutHeading {
  0% {
    opacity: 1;
    transform: translateX(0);

  }

  100% {
    opacity: 0;
    transform: scale(1.8, 1.8) translateX(15%);

  }
}

.zoomOutHeading {
  animation-name: zoomOutHeading;
}


//Heading ANimation
@keyframes displaceContent {
  0% {
    transform: translateY(0);
  }

  100% {

    transform: translateY(800px);
  }
}

.displaceContent {
  animation-name: displaceContent;
}

@keyframes placeContent {
  0% {

    transform: translateY(800px);

  }

  100% {

    transform: translateY(0);

  }
}

.placeContent {
  animation-name: placeContent;
}