.industries-featured-industry {
	width: 30.3333%;
	float: left;
	margin-right: 3%;
	height: 300px;

	@media all and (max-width: $tablet) {
		width: 47%;
	}

	@media all and (max-width: $phone-wide) {
		width: 100%;
		max-width: 300px;
		display: inline-block;
		float: none;
		height: 250px;
	}

	img {
		width: 100%;
		border: 1px solid $color-brand-white;
	}

	.industry-name {
		color: $color-brand-yellow;
		font-size: 1.3em;
		line-height: 1;
		text-transform: uppercase;

		&:hover {
			color: $blue;
		}
	}

}