.site-header {
	background-color: $bg-header;
	height: 100px;

	.content-wrap-full {
		@extend  %clearfix;
		overflow: auto;

		@media all and (max-width: $wrapper-width ) {
			padding-right: 0;
		}
	}
}

.site-logo {
	margin-top: 30px;

	@include media-max($tablet-alt) {
		width: 180px;
	}

	@include media-max($phone-landscape) {
		width: 150px;
	}
}

.site-nav {
	@extend %uc;

	font-weight: $font-weight-regular;
	text-align: right;
	padding-right: 25px;
	padding-top: 20px;
	max-width: 920px;
	@include media-max($header-nav-bp) {
		display: none;
		position: absolute;
		width: 100%;
		left: 0;
		top: 100px;
		background-color: rgba(black, .8);
		text-align: center;
		font-size: 1.5em;
		z-index: 10;

		&.active-nav {
			display: table;
		}
	}
}

.site-nav-secondary {
	margin-bottom: 8px;

	@include media-max($header-nav-bp) {
		display: table-footer-group;
	}
}

.site-nav-secondary-link {
	color: $color-header-nav-second;
	margin-left: 15px;
	line-height: 1em;

	&:hover,
	&.active {
		color: $color-links-light;
	}
	@include media-max($header-nav-bp) {
		display: block;
		margin-left: 0;
		margin-bottom: 10px;
	}
}

.site-nav-primary-link {
	font-size: 1.125em;
	line-height: 1.1;
	margin-left: 15px;
	color: $color-brand-white;

	&:hover {
		color: $color-links-light;
	}
	@include media-max($header-nav-bp) {
		display: block;
		margin-left: 0;
		margin-bottom: 10px;
	}
}


.site-search-toggle {
	background-color: $bg-site-search;
	width: 65px;
	color: $color-brand-white;
	font-size: 30px;
	float: right;
	height: 100px;
}


//Mobile Nav
.mobile-toggle {
	background-color: $bg-site-search;
	color: $color-brand-white;
	width: 65px;
	display: none;
	text-align: center;
	font-size: 2.5em;
	margin-right: 10px;

	@include media-max($header-nav-bp) {
		display: block;
		float: right;
		height: 100px;
	}
}

.mobile-toggle-bars {

	.is-active-mobile &{
		background-color: transparent;

		&:before,&:after {
			top:0;
		}

		&:before{
			transform: rotate(45deg);
		}

		&:after {
			transform: rotate(-45deg);
		}
	}
}

.mobile-toggle-bars:before {
	top: -10px;
}

.mobile-toggle-bars:after{
	bottom: -10px;
}

