﻿.site-map {
    h1 {
        color: black;
    }

    ul {
        padding: 0;

        li {
            list-style-type: none;
            font-size: 1.3em;

            .accordion-title {
                font-size: 1em;
            }

            &.accordion-component .toggle-state > span {
                color: $blue !important;
            }

            &.main-section {
                margin-top: 20px;
                font-size: 1.5em;
            }
            ul{
                padding-left:20px;
                font-size:12px;
            }
        }
    }
}