.mini-banner {
	//background-color: $blue;
	position: relative;
	overflow: hidden;
	margin-bottom: 6px;
	max-width: 400px;

	.wrapping-link {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		z-index: 2;
	}

	.bg-img {
		position: absolute;
		z-index: 0;
		width: 100%;
		top: 0;
		left: 0;
	}

	.text {
		position: relative;
		z-index: 1;
		font-size: 1.7em;
		line-height: 1;
		color: white;
		display: inline-block;
		
		em {
			color: $orange;
		}

	}
	.label{
		font-size:1.25em;
	}
	img{
	  float:left;
	}
	&.media-resources {
		background-color: gray;
		padding: 10px;
	  	word-break: break-word;
		.text {
		  font-size: 1.3em;
		  line-height: 1.3;
		  div:first-child{
			text-transform: uppercase;
		  }
		}

		.email a {
			color: $blue-baby;
		}

		.link a {
			color: $orange;
		  &:after{
			content: '>';
		    color: $blue-baby;
			padding-left:5px;
		  }
		}
	}

	&.blog {
		text-align: left;
		padding: 20px;
		background-color: $orange;
		color: #000;

		.text {
			font-size: 1.2em;
			line-height: 1.2;
		}
	}

}