.home {
	&::-webkit-scrollbar {
	display: none;
	}
  	overflow: -moz-scrollbars-none;
  	-ms-overflow-style: none;
  	@include media-max($home-tablet) {
	  &::-webkit-scrollbar {
		all: unset;
	  }
	  -ms-overflow-style: initial;
	}
	.site-header,
	.site-footer {
		position: fixed;
		left: 0;
		right:0;
		z-index: 10;
	}

	.site-footer {
		bottom: 0;

		@include media-max($home-tablet) {
			position: static;
			left: auto; 
			right: auto;
			bottom: auto;
		}
	}

	.site-header {
		@include media-max($home-tablet) {
			position: static;
			left: auto; 
			right: auto;
			bottom: auto;
		}
	}

	.site-wrapper {
		height: 500%;

		@include media-max($home-tablet) {
			height: auto;
		}
	}
  .fancy-slide-img{
	background-size:cover;
	width:100%;

    @include media-max(1389px) {
      height: 100%;
    }
  }
  .home-layout1{
	.grid-col-50{
	  img{
		width: 58%;
	  }
	}
	.fancy-item-article{
	  .lead{
		margin-bottom:4px;
	  }
	  p{
		margin-bottom:4px;
	  }
	}
  }
  .home-layout2{
	.fancy-item-article{
	  .lead{
		margin-bottom:4px;
	  }
	  p{
		margin-bottom:40px;
	  }
	}
	.home-small-image-links{
	  a{
		display:inline-block;
	  }
	  img{
		width:73px;
		height:73px;
		border:1px solid white;
		margin: 0 15px 15px 0;
	  }
	}
  }
  .home-layout3{
	.lead{
	  margin-bottom:4px;
	}
	.link-label{
	  color:#b2e1ff;
	  font-size:18px;
	  margin-top: 20px;
	  display:inline-block;
	}
	ul{
	  margin:0;
	  padding:0;
	  li{
		list-style-type: none;
		border-bottom:1px solid #454545;
		a{
		  font-size:18px;
		  color:#00a0dc;
		}
		&:last-child{
		  border-bottom:0;
		  margin-bottom:8px;
		}
	  }
	}
  }
}