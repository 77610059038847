@media print {
  * {
    text-shadow: none !important;
    color: #000 !important;
    background: transparent !important;
    box-shadow: none !important;
  }
  body{
    width: 80%;
    font-size: 20px; 
    margin:0;
    padding:0;
  }
  pre,
  blockquote {

    page-break-inside: avoid; }

  thead {
    display: table-header-group; }

  //tr,
  //img {
  //  page-break-inside: avoid; }

  img {
    max-width: 100% !important;
  }

  .site-main img  {
    width: auto !important;
  }

  //p,
  //h1,
  //h2,
  //h3 {
  //  orphans: 3;
  //  widows: 3;
  //}
  .global-wrapper{
    padding-top: 20px;
  }
  .global-header{
    position:relative;
    .print-logo{
      display:block;
    }
  }

  select {
    background: #fff !important; }

  .navbar {
    display: none; }

  .table td,
  .table th {
    background-color: #fff !important; }

  .btn > .caret,
  .dropup > .btn > .caret {
    border-top-color: #000 !important; }

  .label {
    border: 1px solid #000; }

  .table {
    border-collapse: collapse !important; }

  header .wrapper,
  .mobile-nav-wrapper,
  footer,
  .pdf-link,
   .view-all-trigger,
  .section--related .rel-articles,
  .more-btn-wrapper,
  .download,
  .subscribe-list,
  .load-more,
  .btn,
  .read-more:after, .pdf-link:after, .exp-listing__link:after, .box li a:after, .box__abstract p a:after{
    display: none !important;
  }

  body {
    margin: 0;
  }

  span.label{
    border: none;
  }

  .print-logo {
    display: block;
    width: 250px;
  }

  .site-banner {
    padding-left: 0;

    .page-title {
      padding: 0;
    }
  }

  //from pdf.scss
  .site-logo{
    display: none;
  }
  .pl20{
    .site-logo{
      margin-left:0;
    }
  }
  .pdf-only{
    display:block;
  }
  .social-links,.toggle-state{
    display: none;
  }
  .content-wrap-left,.content-wrap-right{
    //padding-left:0 !important;
    width:100%;
    padding-top:0 !important;
  }
  .site-main-content{
    margin:0 !important;
  }
  .accordion-component .accordion-content{
    display:block !important;
  }
  .industry{
    .grid-content-med-half{
      width:90%;
    }
    .content-wrap-right{
      //padding:0 40px;
    }
    .practice-highlight-item{
      width:30%;
      margin-right:1%;
      height:120px;
      &:nth-child(2n){
        margin-right:1%;
      }
      .card-front{
        display:none;
      }
      .card-content{
        a{
          display: none;
        }
      }
      &:last-child{
        margin-bottom:20px;
      }
      .background-cover{
        display:inline-block !important;
      }
    }
    li{
      .name{
        font-size: 22px;
        width:100%;
        display:inline-block;
      }
      .role{
        position: relative;
        top:-8px;
      }
    }
  }

  [data-flip-card]{
    float:left;
    display: inline-block;
    width:300px;
    .card {
      .card-front{
        img{
          display: none!important;
        }
      //  transform:rotatey(180deg);
      //  display:none;
      }
      .card-back{
        position: relative;
        transform:rotatey(0deg);
        .connect,.bio-link{
          display: none;
        }
      }
      .background-cover{
        display:none;
      }
    }
    .name{
      display:inline-block;
    }
  }
  .professional-card{
    .card{
      .card-back{
        width:260px;
      }
    }
  }
  .grid-content-large-75, .grid-content-large{
    width:95%;
  }
  //.grid-content-small-quarter{
  //  width:24%;
  //}
  .grid-content-large-75.fr.pl20{
    float:left !important;
    padding-left:0;
  }
  .people-detail{
    .bio-card{
      float:left;
      width:75%;
    }

    .content-wrap-left{
      padding:40px;
    }
    .card-content .card-front{
      display:none;
    }
    .list-office-item{
      a,.list-office-phone,.list-office-fax{
        display:block;
      }
    }

    .practice-highlight-item{
      width:200px;
      margin-right:20px;
      display:inline-block;
      h3{
        font-size:1em;
        font-weight: 500;
      }
      &:last-child{
        margin-bottom:30px;
      }
      .card-back{
        a{
          display:none;
        }
      }
    }
  }
  p{
    max-width:100%;
  }
  .bio-card{
    .media-content{
      width:70%;
    }
    .content-block-title{
      color:$blue;
      background-color:$grey-light;
    }
    .media-img{
      display:inline-block;
    }
  }

}