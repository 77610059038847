.lead {
	@extend .h3-size;

	line-height: 1.4;
	color:$color-text-lead;
	text-transform: uppercase;
  	strong{
	  font-weight: normal;
	}
}


.emphasis {
	color: $color-brand-blue;
}

.grid-content-sidebar {

	p {
		margin: 0 0 10px;
	}

	.list-basic {
		li {
			margin: 0 0 5px;

		}
	}
}

.list-nomargin{
	margin: 0px;
}

.detail-content-accolades {
	.rich-text {
		line-height: 2
	}
}

.detail-content-accolades .rich-text p + ul{
    margin-top:0px;
}

.border-image {
	border: 1px solid $border-color;
}


.rich-text {
	.pull-left {
		float: left;
		margin: 10px 3% 10px 0;
	}
	p + ul {
	  margin-top:-10px;
	}
}
