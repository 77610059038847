.site-footer {
	@extend %clearfix;
	
	min-height: 70px;
	color: $color-footer-links;
	background-color: $bg-site-footer;
	font-weight: $font-weight-regular;
	font-size: .9em;
	padding: 20px 0;
	text-align: right;
	position: relative;
	z-index: 5;
	clear:both;

	@include media-max($phone-wide) {
		text-align: center;
	}

	.icon {
		color: $color-brand-white;
		margin-left: 5px;
	}

	.social-links {
		margin-left: 5%;
	}

	.footer-links,
	.social-links,
	.footer-links-item  {
		display: inline-block;
	}

}

.footer-links-item {
	@extend %uc;
	margin-left: 8px;
	
	@include media-max($phone-wide) {
		float: none;
	}
}

.footer-links-item,
.site-footer-info {
	color: $color-footer-links;
}

.social-links-label {
	color: $color-brand-white;
}