.about-diversity {

	.top-content {
		overflow: hidden;
	}

	.landing-link {
		border-bottom: 1px black solid;
		font-size: 1.2em; 
		font-weight: $font-weight-light;  
		line-height: 1.1;
	}
	.professional-cards-2x2{
	  margin-top: 20px; 
	}
}
