.industries-landing {
	color: white;
	padding: 3.5em 0;

	.subheading {
		color: $color-grey-text;
		margin-bottom: 0;
		font-weight: 300;
		font-size: 1.7em;
	  	padding-bottom: 10px;
	}
	.all-industries{
	  background-color: rgba(0,0,0,.7);
	  overflow: hidden;
      .subheading {
          display: inline-block;
      }
	}
	.featured-industries {
		overflow: hidden; 
	  	background-color: rgba(0,0,0,.7);

		@include media-max($phone-wide) {
			margin-bottom: 20px;
		}
	}

	.industry-list {
		@media all and (max-width: $tablet) {
			width: 100%;
			float: none;
			margin-right: 0;
		    background-color: transparent;
		}
	}
}