@font-face {
	font-family: 'dorsey';
	src:url('../../fonts/dorsey.eot?73zeoe');
	src:url('../../fonts/dorsey.eot?73zeoe#iefix') format('embedded-opentype'),
		url('../../fonts/dorsey.ttf?73zeoe') format('truetype'),
		url('../../fonts/dorsey.woff?73zeoe') format('woff'),
		url('../../fonts/dorsey.svg?73zeoe#dorsey') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class*="icon-"]:after {
	content: ' ';
}

[class*="icon-"]:before {
	font-family: 'dorsey';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1 !important;
	vertical-align: middle;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

%icon-style {
	font-family: 'dorsey';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1 !important;
	vertical-align: middle;	

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-x:before {
  content: "\e902";
}
.icon-youtube-alt:before {
  content: "\e600";
}
.icon-search:before {
  content: "\e601";
}
.icon-chevron-thin-down:before {
  content: "\e602";
}
.icon-chevron-thin-left:before {
  content: "\e603";
}
.icon-chevron-thin-right:before {
  content: "\e604";
}
.icon-chevron-thin-up:before {
  content: "\e605";
}
.icon-arrow-down:before {
  content: "\e606";
}
.icon-arrow-left:before {
  content: "\e607";
}
.icon-arrow-right:before {
  content: "\e608";
}
.icon-arrow-up:before {
  content: "\e609";
}
.icon-v-card:before {
  content: "\e60a";
}
.icon-mail:before {
  content: "\e60b";
}
.icon-twitter:before {
  content: "\f09b";
}
.icon-facebook:before {
  content: "\f09c";
}
.icon-instagram:before {
	content: "\f16d";
}
.icon-rss:before {
  content: "\f09f";
}
.icon-google-plus:before {
  content: "\f0d6";
}
.icon-linkedin:before {
  content: "\f0e2";
}
.icon-youtube-play:before {
  content: "\f16b";
}
.icon-file-pdf-o:before {
  content: "\f1c2";
}
.icon-printer:before {
  content: "\e954";
}
.icon-arrow-circle-left:before {
  content: "\e900";
}
.icon-arrow-circle-right:before {
  content: "\e901";
  color: gray;
}
