.search{
  .landing-link {
    border-bottom: 1px black solid;
    font-size: 1.2em;
    font-weight: $font-weight-light;
    line-height: 1.1;
  }
  .search-results{
    line-height:1;
    font-family: 'Roboto', Arial, sans-serif;
    .gs-webResult.gs-result a.gs-title:visited, .gs-webResult.gs-result a.gs-title:visited b, .gs-imageResult a.gs-title:visited, .gs-imageResult a.gs-title:visited b{
      color:$blue;
      font-family: 'Roboto', Arial, sans-serif;
    }
    .gs-result .gs-title, .gs-result .gs-title *,.gs-webResult.gs-result a.gs-title:link, .gs-webResult.gs-result a.gs-title:link b, .gs-imageResult a.gs-title:link, .gs-imageResult a.gs-title:link b{
      color:$blue;
      font-family: 'Roboto', Arial, sans-serif;
    }
    .gsc-table-result, .gsc-thumbnail-inside, .gsc-url-top{
      padding:0;
      font-family: 'Roboto', Arial, sans-serif;
    }
    .gsc-thumbnail-inside{
      line-height: 1.5;
    }
    .gsc-orderby,.gcsc-branding-text,.gsc-above-wrapper-area,.gcsc-branding-img-noclear {
      display:none;
    }
    .cse .gsc-control-cse, .gsc-control-cse{
      padding:0;
    }
    .gsc-cursor-box.gs-bidi-start-align{
      margin:0;
      .gsc-cursor-page{
        color:$blue;
        font-family: 'Roboto', Arial, sans-serif;
        font-size: 120%;
      }

    }
  }
}