.select-dropdown {
	position: relative;
  	//we may need to get the below into
	//select.desktopDropDown :: -ms-expand {
	//  display: none;
	//}
	height: 40px;

	select {
		top: 0;left: 0;
		height: 100%;
		width: 100%;
		z-index: 5;
		cursor: pointer;
		display: block;
		padding: 0px 15px;
	  	padding-right:0\9;
		text-align: left;
		position: relative;
		background-color: $opaquegrey;
		color: $color-text;
		-webkit-appearance: none;
		-moz-appearance: none;
		-ms-appearance: none;
		-o-appearance: none;
		appearance: none;
	  	appearance: none;
	  	font-weight: bold;
	  	border:none;
	}

	.icon {
		position: absolute;
		right: 15px;
		font-size: 12px;
		top: 35%;
	}
}
select::-ms-expand {
  display: none;
}

.select-dropdown-label {
	display: block;
  	display:none\9;
	background-color: $grey-light;
	color: $color-text;
	&:after {
		@extend %icon-style;

		content: "\e606";
		position: absolute;
		right: 10px;
		font-size: 25px;
		opacity: .5;
		z-index: 6;
		top: 9px;
	  	cursor: pointer;
	  	pointer-events: none;
	}
}
.select-clear-label{
  position: absolute;
  right: 12px;
  top: 5px;
  cursor: pointer;
  z-index: 10;
  color: $color-text;
  i{
	font-size: 28px;
	opacity: .4;
  }
}
