$grid-content-spacing: 3em;

.grid-content {
	@extend %clearfix;
	position: relative;
	clear: both;
}

.grid-content-sidebar,
.grid-content-main {
	float: left;

	@include media-max($phone-wide) {
		float: none;
	}
}

.grid-content-sidebar {
	width: 25%;
	max-width: 250px;
	line-height: 1.4;

	@include media-max($phone-wide) {
		width: 100%;
	}
}

.grid-content-main {
	width: 75%;
	
	// body:not(.black) & {
	// 	background-color: rgba(white,.7);
	// }

	@include media-max($phone-wide) {
		
		width: 100%;
	}
}	

.grid-content-sidebar-right {
	float: right;
	width: 20%;
	padding-right: 10px;

	@media all and (max-width: $tablet) {
		width: 100%;
		padding-right: 0;
	}
}

.grid-content-right {
	width: 77.3%;
	float: right;

	@media all and (max-width: $tablet) {
		width: 100%;
	}

	@media all and (max-width: $phone-wide) {
		float: left;
	}
}

.grid-content-tab-slides  {
	margin-top: 0;

	.grid-content-nav {
		float: left;

		@include media-max($tablet-alt) {
			width: 30%;
		}

		@include media-max($phone-wide) {
			margin-left: 0;
			width: 100%;
			float: none;
		}


	}

	> .grid-content-main {
		overflow: hidden;
		float: right;

		@include media-max ($tablet-alt) {
			width: 66%;
		}

		@include media-max($phone-wide) {
			margin-left: 0;
			width: 100%;
			float: none;
		}
	}
}

.grid-content-tab-slide {

	.grid-content-main {
		margin-left: 0;
		width: 68.5%;
		padding-right: 5.5%;
		position: relative;

		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 25px;
			bottom: 0;
			right: 3.5%;
			border-right: 1px solid $grey-darkest;
			border-left: 1px solid $grey-med;
		}

		@include media-max ($tablet-alt) {
			width: 100%;

			&:after {
				display: none;
			}
		}

		@include media-max($phone-wide) {
			float: none;
		}
	}

	.grid-content-main-full {
		width: 100%;
		padding-right: 0;

		&:after {
			display: none;
		}
	}

	.grid-content-sidebar {
		width: 31.5%; //magic number - matches the width of the navigation

		@include media-max ($tablet-alt) {
			width: 100%;
			
		}
	}
}