.tab-nav-wrap {
	background-color: $opaqueblack;
	padding:10px 20px ;
	font-weight: $font-weight-regular;
	position: relative;
	text-align: right;

	@include media-max($tablet-alt) {
		margin-left: -10px;
		padding: 10px;		
	}

	@include media-max($phone-wide) {
		text-align: left;
		margin-left: 0;
		margin-bottom: 20px;
	}
}

.tab-nav {
	
	@include media-max($phone-wide) {
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		background-color: $opaqueblack;
		z-index: 5;
		overflow: hidden;
		max-height: 0;
		transition: max-height 1s ease-out;
	}
}

.tab-nav-link {
	color: $color-brand-white;
	display: block;
	margin-bottom: 12px;
	line-height: 1.5;

	&.is-active,
	&:hover {
		color: $color-links;
	}

	@include media-max($phone-wide) {
		padding: 0 10px;

		&.is-active {
			color: $color-brand-white;
		}
	}
}

.tab-nav-link-sec {
	color: $blue-baby;

	&:first-child {
		border-top: 1px solid white;
		padding-top: 12px;
	}
}

.tab-nav-label {
	color: $color-brand-white;
	position: relative;
	cursor: pointer;
	display: none;

	@include media-max($phone-wide) {
		display: block;

		&.is-active-nav {
			color: $color-links;

			& + .tab-nav {
				max-height: 300px;
			}

			.icon {
				transform: rotate(180deg);
			}
		}
	}

	.icon {
		font-size: 25px;
		position: absolute;
		right: 0;
		top: -.5em;
		transition: transform .3s ease-in;
	}
}