.industry {
	//overflow: hidden; this seems to fix an ie issue.  If we need it back we need to fix a footer bug in IE

	.content-wrapper.nopadding {
		padding: 0;
		width: 100%;
	}

	header {
		.h3-size {
			margin: 0;
		}
		

		@media all and (max-width: $tablet) {
			&.grid-content-right {
				width: 66%;
			}
		}
	}

	.grid-content-main {
		float: left;
		width: 75%;
		border-right: 1px solid $border-color;

		@media all and (max-width: $tablet) {
			width: 70%;
		}

		@media all and (max-width: $phone-wide) {
			margin-top: 20px;
			width: 100%;
			border-right: none;
		}
	}

	.accordion-wrapper {
		overflow: hidden;
		margin: 40px 0;
		
		.grid-content-main {
			float: right;
		}
	}

	.calls-to-action {
		overflow:hidden;
		justify-content: space-between;
		margin-bottom: 10px;
	}

	.cta {
		background-color: #31A1D2;
		border: none;
		text-align: center;
		text-decoration: none;
		font-size: 1em;
		line-height: 1.4;
        float:left;
		width: 49%;
        margin-right:1%;
        margin-top:1%;
		padding: 5px 10px 5px 10px;
		&:nth-child(odd) {
            clear:left;
		}
		.action-text {
			color: black;
		}

		.resource-title {
			color: white;
		}

		span {
			text-transform: uppercase;
			display: block;
		}
	}
	
	.related-news {
		background-color: $opaqueblack;
		padding: 15px;
	}

	.related-news-title {
		margin-bottom: 10px;
		color: $yellow;
		font-size: 1.5em;
	}

	.related-news-link {
		color: $blue;
	}

	.related-news-date {
		color: white;
		margin-bottom: 10px;
	}
	.service-subpage{
	  font-size: 1.3em;
	  white-space:nowrap;
	  i{
		color:$black;
		font-size: .9em;
		position: relative;
		bottom:2px;
		font-weight: bold;
		margin-left:5px;
	  }
	}
	.accordion-wrapper {
		float: right;
		width: 75%;
		overflow: hidden;
	}
	.js-tab-slides-wrapper{
	  background-color: rgba($white, .7);
	}
	.content-wrapper {
		overflow: hidden;
	}
  	.professional-card{
	  .card-front{
		a{
		  color:$blue;
		}
	  }
	}

    @media print {
        .content-block + .content-block {
            padding-top: 0; }
    }
}