.news {
	margin-top: 50px;
	position: relative;
	overflow: hidden;
	margin-top:3.5em; 
	min-height: calc(80% - 10px);
	.site-hero-title{
		@include media-max( $phone-wide)   {
			font-size: 3.5em;
		}
	}
	.news-top, .news-landing-top {
		width: 65%;
		float: left;
		margin-bottom: 50px;
		background-color: rgba($white, .7);

		.article {
			width: 100%;
			margin-bottom: 0px;
		}
		
		@media all and (max-width: $tablet-landscape) {
			width: 100%;
		}
		
		@media all and (max-width: $phone-wide) {
		  	margin-bottom: 20px;
		}
	}
    .news-landing-top {
        margin-bottom: 0;
    }
    .featuredArticles > div > .article {
        width: 65%;
        margin-bottom: 50px;
        min-height: 570px;
		
		@media all and (max-width: $tablet-landscape) {
			width: 100%;
		}
		
		@media all and (max-width: $phone-wide) {
		  	margin-bottom: 20px;
		}
    }
	.news-, 
	.news-sidebar {
		float: right;
		width: 30%;
		@media all and (max-width: $phone-wide) {
			width: 100%;
		}
	  .mini-banner{
		max-width: 300px;
	  }
	}

	.sub-menu {                              
		padding: 15px 0;
		border-top: 1px solid $grey-light;
		border-bottom: 1px solid $grey-light;
		line-height: 1;
		margin-bottom: 10px;
		font-size:1.2em;
 
		text-align: justify;
		-ms-text-justify: distribute-all-lines;
		text-justify: distribute-all-lines;
		-webkit-text-justify: auto;
 
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between; 
	}
 
    .sub-menu-item {
		color: gray;
		text-transform: uppercase;
		position:relative;
		vertical-align: top;
		display: inline-block;
		white-space: nowrap;
                               
		&:hover {
			color: $blue;
		}
 
		&.active {
			color: $color-dark-blue;
		}

		@media all and (max-width: $phone-wide) {
			width: 49%;
			margin-bottom: 12px;
		}		
    }

	.sub-menu-filler {
		display: inline-block;
		position: relative;
		width: 100%;
		height: 0;
		font-size: 0;
		line-height: 0
	}

	.news-search .accordion-title {
		color: $orange;
	}
	.featured-resource{
	  .article-container{
		.category{
		  display: none;
		}
		a.title{
		  font-size:32px;
		  color:#00a0dc;
		  font-weight:400;
		}
		.date{
		  font-size:18px;
		}
	  }
	}
	.section-header {
		clear: left;
	}
	.news-results-filters{
	  margin: 0 0 15px 0;
	}
  	.achievement-results{
	  width:65.33333%;
	  margin-right:1%;
	  float:left;
	  margin-bottom:50px;
	  min-height:80px;
	}
	@media (max-width: $tablet-landscape){
	  .resources{
		height:0;
		width:0;
		visibility: hidden;
	  }
	  .achievement-results{
		width:100%;
	  }
	}
}
