.people-landing{
  .grid-content-small{
    width:29.5%;//strange FF bug where it can't be exactly 30%
  }
  //.people-search{
  //  &+.grid-content{
  //    clear: none;
  //  }
  //}
  .search-labels{
    clear:both;
    display: block;
  }
  .highlight-item .rich-text{
    background-color: $bg-site-footer;
  }
  @media (max-width: $phablet){
    //maybe we should do a display none
    .grid-content-small{
      display:none;
    }
  }
}