.highlight-item {
	padding: 10px;
	overflow: hidden;

	@include media-max($tablet-alt) {
		margin-left: -10px;
		
	}
	img.pull-left {
	}
	.rich-text{
	  width:200px;
	  margin-bottom:20px;
	  min-height: 120px; 
	  &.left{
		padding:14px 8px;
		border:solid 1px $grey-med;
		img{
		  max-width: 35%;
		  margin-right: 10px;
		}
	  }
	}
  	img{
	  display: block;
	}
	h3,
	p {
		margin: 0;
		display: table;
	}
}
.grid-content-small-quarter{
	.highlight-item{
		.rich-text{
			width:auto;
		}
	}
}
.highlight-client-quote {
	width: 30%;
	margin-left: 5%;
	height: 610px;

	@include media-max( $wrapper-width ) {
		height: 570px;
	}

	@media all and (max-width: $tablet-landscape) {
		display: none;
	}
}



//homepage highlights
.fancy-items-highlights {
	//overflow: hidden; // REMOVED FOR IE 9/10
	clear:both;
	padding-top: 25px;
	&:after{
	  content: " ";
	  display: block;
	  clear: both;
	}
	.highlight-item {
		float: left;
		line-height: 1.6;
		margin-right: 2%;
		margin-left: 0;
		border: 0 none;
		min-height: 170px;
		padding: 0;


		@include media-max($home-tablet) {
			
			margin-bottom: 2%;

			&:nth-child(2n+1) {
				clear: left;
			}
		}

		@include media-max($home-mobile) {
			width: 100%;
			float: none;
		}

		a {
			font-style: italic;
		}
	}

	&.no-float .highlight-item {
		float: none;
		width: 100%;
		margin-right: 0;
		margin-bottom: 10px;
	}

	&.black-border .highlight-item {
		border: 1px solid black;
	}

	.highlight-item-simple {
		border: 0 none;
		padding: 10px 0;
	}
}
