html, 
body,
.site-wrapper {
	height: 100%;
}

body{
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-position: 100% 100%;
  	background-size: cover;
	min-width: $phone;
	&.contain{
	  background-size:contain;
	}
  	&.ipad{
	  background-attachment: fixed;
	  background-position: bottom;
	  background-size: contain;
	}
  	@media all and (max-width: $phone-wide) {
  		background-image: none !important;
  	}
}

img {
	max-width: 100%;
}

p {
	max-width: 700px;
}

.content-wrapper {
	width: $site-max-width - $site-search-width - $site-content-padding;
	margin: 0 auto;
	padding: 0 10px;
	
	header.site-header &,
	footer.site-footer & {
		background-color: transparent;
	}

	@media all and (max-width: $site-max-width) {
		width: 100%;
	}

	@include media-max($tablet-alt) {
		padding: 0 15px;
	}
}

.site-main-content {
	@extend %clearfix-micro;
	
	margin: 3.5em auto;
	position: relative;
	z-index: 5;
	min-height: calc(80% - 55px);
  	&.location-detail{
	  margin: 3.5em 0;
	}
}

.landing-image {
	position: fixed;
	top: 0;
	right: 0;
	height: 100%;
	z-index: -1;
}

.landing-detail-img {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: url('/images/industry-bg.jpg') no-repeat 100% 100%;
	background-size: cover;
	z-index: -1;
	-webkit-transform: translate3d(0, 0, 0);
	-webkit-backface-visibility: hidden;
}


.media-img,
.media-content {
	float: left;
}

.read-more-link{

}
.read-more-content{
  visibility: hidden;
  opacity: 0;
  height:0;
  transition: none;
  float:left;
  &.show{
	visibility: visible;
	opacity: 1;
	height:100%;
	transition: all .5s ease-in-out;
	float:none;
  }
}
//youtube html5
//video {
//  width: 100%    !important;
//  height: auto   !important;
//}

//Rich text global classes
.twoColumn{
  column-count: 2;
  li{
	list-style-type: disc;;
	margin-left:20px;
  }
	@include media-max($tablet-landscape) {
		padding-left: 10px;
	}
	@include media-max($tablet-alt) {
		padding-left: 0;
	}
	@include media-max($phone-wide) {
		padding-left: 10px;
		column-count:1;
	}
}