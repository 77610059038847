.active-filters{
  > span{
    background:$grey-lightest;
    padding:4px;
    margin-right:10px;
    border-radius: 5px;
    button{
      color:$color-brand-blue;
      text-transform: uppercase;
      font-size: 25px;
      position: relative;
      top: 4px;
    }
    span{
      margin-left:4px;
    }
  }
}