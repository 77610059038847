.clear-both{
    clear: both;
}

.pull-left {
	float: left;
}

.pull-right {
	float: right;
}

.oh {
	overflow: hidden;
}

.fl {	
	float: left;
}

.fr {	
	float: right;
}
 
.pl30 {
	padding-left: 30px;
}

.pr30 {
	padding-right: 30px;
}

.pl20 {
	padding-left: 20px;
}

.pr20 {
	padding-right: 20px;
}

.pt30 {
    padding-top: 30px;
}

.pl30,
.pr30,
.pl20,
.pr20 {
	@media all and (max-width: $phone-wide) {
		padding: 0;
	}
}

.ttu {
    text-transform: uppercase;
}

.brg {
	border-right: 1px solid $border-color;

	@media all and (max-width: $phone-wide) {
		border-right: 0;
	}
}

.mobile-only {
	display: none;
	
	@include media-max($tablet-alt) {
		display: none;
	}
	
}