.site-search {
	@extend  %clearfix-micro;
	position: relative;
	display: none ;

	.search-input {
		background-color: $opaquegrey;
		width: 100%;
		height: 100%;
		font-size: 1.3em;
		float: right;
		width: 645px;

		@include media-max($header-nav-bp) {
			width: 100%;
		}
	}

	.search-button {
		position: absolute;
		right: 0;
		top: 0;
		background-color: $color-brand-baby-blue;
		border: none;
		color: #000;
		padding: 0 25px;
		font-size: 1.2;
		top: 0;
		bottom: 0;
		margin: 0;
	}
}