.news-search {

	.search-box {
		position: relative;
		height: 50px;
		margin-bottom: 10px;
	}

	.keyword-search {
		width: 100%;
		z-index: -1;
	}

	.icon-search {
		position: absolute;
		right: 20px;
		top: 50%;
		transform: translateY(-50%);
	}

	select,
	.keyword-search {
		height: 50px;
		width: 100%;
		margin-bottom: 10px;
		background-color: $grey-lightest;
	  	border-color: $grey-lightest;
	}
	.select-dropdown{
	  display:inline;
	  width:100%;
	}
	select {
		padding: 0 38px 0 10px;
	  	padding-right:0\9;
	}

	&.client-achievement {
		margin-bottom: 20px;
		.accordion-component{
		  border-bottom: 1px solid #ccc;
		  padding-bottom:10px;
		}
	  .client-achievement-results-filters{
		padding-top:10px;
	  }
		.client-achievement-select {
			width: 32.6666%;
			margin-right: 1%;
			display:inline-block;
			@media (max-width: 768px) {
			  width: 100%;
			  margin-bottom: 0;
			}
			&:last-child {
				margin-right: 0;
			}

			@media all and (max-width: $phone-wide) {
				width: 100%;
				margin-bottom: 0px;
			}
		}
	}
	.select-dropdown-label{
	  &:after{
		top:13px;
		right:15px;
	  }
	}
	.accordion-title {
		clear: left;
	}
}
