.practice-highlight-item {
	position: relative;
	height: 220px;
	width: 48%;
	float: left;
	overflow: hidden;
	margin-top: 20px;
	color: #fff;
	&.blue{
	  .card-back{
		background-color:$color-dark-blue;
	  }
	}
	&.light-blue{
		.card-back{
		  background-color:$blue;
		}
	  }
	&.brown{
		.card-back{
		  background-color:$maroon;
		}
	}
	@media all and (max-width: $tablet) {
		width: 100%;
		float: none;
	}

	&:nth-child(2n) {
		margin-right: 2%;

		@media all and (max-width: $tablet) {
			margin-right: 0;
		}
	}
	
	.practice-background {
		position: absolute;
		width: 100%;
	}
	
	.practice-title {
		position: absolute;
		margin: 10px;
		color: $orange;
		font-weight: 400;
	}

	h3 {
		margin-bottom: 15px;
		margin-top: 0;
		line-height: 1.1;
		@media (max-width: $tablet-landscape) {
		  font-size:1.2em;
		}
	}

	a {
		display: block;
		line-height: 1;
		margin-bottom: 10px;
	}

	.card .container .background-cover {
		background-color: rgba(#000, 0);
	}
  	.card{
	  .container{
		color: $orange;
		position:relative;
		a {
		  color: $color-brand-baby-blue;
		  i{
			transition: all 0.3s ease-in-out;
		  }
		  &:hover{
			color:darken($color-brand-baby-blue,15%);
			i{
			  position:relative;
			  right:-5px;
			}
		  }
		  &.flip-card{
			font-size:26px;
			float:right;
			position:absolute;
			&:hover{
			  i{
				right:0;
			  }
			}
		  }
		}
		.card-front{
		  .flip-card{
			bottom:10px;
			right:20px;
		  }
		}
		.card-back{
		  .card-content{
			min-height:130px;
		  }
		  .flip-card{
			bottom:-40px;
			right:0px;
		  }
		}
	  }
	}

}