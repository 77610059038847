
//  Brandon Mathis Media Queries 
///////////////////////////////////////////////////////////////////////////////
//
//	http://codepen.io/imathis/pen/BDzcI
//
//    You should use the Breakpoint Aliases (below) when referencing media query
//    breakpoints with your custom CSS
//
///////////////////////////////////////////////////////////////////////////////

$phone:        		320px;
$phone-landscape:  	480px;
$phone-wide:   		600px;
$phablet:      		650px;
$tablet-alt:       	720px;
$tablet:       		850px;
$tablet-landscape:  1024px;
$desktop-screen: 	1100px;
$large-screen: 		1300px;
$people-detail:     1468px;
$x-large-screen: 	1500px;

$wrapper-width: 	1195px;


//Element Specific Breakpoint
$header-nav-bp: 	950px;
$home-large:		850px;
$home-tablet:		750px;
$home-mobile:		550px;

//Define Min
@mixin media-min($device-width) {
  @media screen and (min-width: $device-width) {
    @content ;
  }
}

//Define Max
@mixin media-max($device-width) {
  @media screen and (max-width: $device-width - 1) {
    @content ;
  }
}
