.people-detail {

	&.careers-detail {
		.media-content {
			padding-left: 30%;

			@media all and (max-width: $tablet) {
				padding-left: 0;
			}
		}
	}

	.highlights {
		float: left;
		width: 23%;

		@media all and (max-width: $tablet) {
			float: none;
			width: 100%;
		}
	}

	.content-wrapper {
		float: right;
		width: 70%;

		@include media-max($header-nav-bp) {
			float: none;
		}

		@include media-max($tablet-alt) {
			padding-top: 20px;
		}
	}

	.grid-content-main {
		width: 60%;
	}

	.content-wrap-left {
		padding-left: 33%;
		padding-right: 75px;

		@include media-min($people-detail) {
			padding-left: 20%;
		}

		@media (min-width: $large-screen) and (max-width:$people-detail) {
			padding-left: 28%;
		}

		@include media-max($header-nav-bp) {
			padding-left: $site-content-padding;
		}

		@include media-max( $tablet-alt ) {
			padding-left: $site-content-padding-md;
		}

		@include media-max($phone-wide) {
			padding: 0 5%;
		}
	}

	.practice-highlight-item {
		width: 70%;

		@media all and (max-width: $tablet) {
			width: 100%;
		}
	}

}
