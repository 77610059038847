//  Fonts
///////////////////////////////////////////////////////////////////////////////
//
//	quick link to google fonts
//  http://www.google.com/fonts#UsePlace:use/Collection:Roboto:400,400italic,500italic,500,700,300,100
//
///////////////////////////////////////////////////////////////////////////////

//Type
$font-primary:  			'Roboto', Arial, sans-serif;

//weights
$font-weight-thin:			100;
$font-weight-light:			300;
$font-weight-regular:		400;
$font-weight-medium:		500;
$font-weight-semibold:		600;
$font-weight-bold:			700;

$font-weight-base: 			$font-weight-medium;


//Sizes
$font-size-base:        	80% !default;
$line-height-base:			1.8;


//  Colors
///////////////////////////////////////////////////////////////////////////////
//
//	All Colors
//
///////////////////////////////////////////////////////////////////////////////

//Generic
$black: 					#1A1A1A;
$white: 					#fff;

$black-shade:				#1F2A30;

//grays
$grey-lightest:				#ebebeb;
$grey-light:				#aeaeae;

$grey-med:					#bfbfbf;
$grey-med-alt:				#AEAEAE;
$grey-darkest:				#3F3F3F;

//blue
$color-dark-blue:			#0f598c;
$blue:						#38a0d1;
$blue-light:				#b2e1ff;
$blue-light-card:           #68CBF1;
$blue-baby:					#C8E4F1;



//orange
$orange:					#c9910d;
$yellow: 					#EEB553;

//red
$maroon:					#652313;	

//opacities
$opaquewhite: 				rgba(255,255,255,0.5);
$opaqueblack: 				rgba(0,0,0,0.75);
$opaquegrey:				rgba(174,174,174,0.9); //changed this to .9 since IE cursor hides with more opacity
$opaquewhitecontent: 		rgba(255,255,255,0.82);

//brand Color
$color-brand-black: 		$black;
$color-brand-white: 		$white;
$color-brand-blue: 			$blue;
$color-brand-baby-blue: 	$blue-baby;

$color-brand-orange: 		$orange;
$color-brand-yellow:		$yellow;

//Text Colors
$color-text: 				$color-brand-black;
$color-links:				$color-brand-blue;
$color-links-light:			$blue-light;
$color-text-lead: 			$orange;
$color-related-heading: 	$maroon;
$color-grey-text:			$grey-light;
$color-disabled:			$grey-med-alt;
$color-sidebar-colors:		#1d4f85;
$color-article-outline:     #cccccc; 
//header
$color-header-nav-second:	$grey-light;
$color-footer-links:		$grey-light;

//backgrounds
$bg-header: 				$color-brand-black;
$bg-site-search: 			$black-shade;
$bg-site-footer: 			rgba(0,0,0,0.70);
$bg-social-inpage:			rgba(200, 228, 241, .8);
$bg-btn-default:			$blue-baby;


//Borders
$border-darkest:			$black-shade;
$border-color:				#ababab;


//  Spacing Between Elements
///////////////////////////////////////////////////////////////////////////////
//
//	Spacing between each section, element, etc....
//
///////////////////////////////////////////////////////////////////////////////

$spacing-unit:          10px !default;
$spacing-unit-half:     $spacing-unit / 2 !default;
$block-spacing:         $spacing-unit * 2 !default; //40px


//  Layout Widts
///////////////////////////////////////////////////////////////////////////////
//
//	Max-width, global padding,etc....
//
///////////////////////////////////////////////////////////////////////////////

$site-max-width:        1300px !default;
$site-header-height:  	99px !default;
$site-content-padding:  40px !default;
$site-content-padding-md:  20px !default;

$site-search-width:  	60px !default;


//  Animations
///////////////////////////////////////////////////////////////////////////////
//
//	Types, Eases, timing,etc
//
///////////////////////////////////////////////////////////////////////////////

$animation-basic: 		color .2s ease-in,
						background-color .25s ease-in;


