.careers-detail{
	.sub-title {
	  margin-bottom: 20px;
	  color: $maroon;
	}
	.content-wrap-right{
	  h2{
		color:$color-dark-blue;
		font-size: 2.05em;
		font-weight: $font-weight-light;
		line-height: 1;
		margin-bottom: 6px;
	  }
	  .drop-down{
		height: 40px;
		width: 100%;
		margin-bottom: 10px;
		background-color: $grey-lightest;
		font-weight:bold;
		padding:0 10px;
	  }
	  .search{
		padding:12px 20px;
		background:$color-brand-baby-blue;
		font-weight: bold;
	  }
	  .grid-content-med-half, .grid-content-small-quarter{
		background-color: rgba(255, 255, 255, 0.7);
	  }
	}


  &+.careers-background {
	position: relative;

	@include media-max($header-nav-bp) {
	  display: none;
	}

	> img  {
	  position: fixed;
	  top: 180px;
	  z-index: 1;
	  width: 30%;
	  right: 0;

	  @media all and (max-width: $large-screen) {
	  	right: -5%;
	  }
	  @media all and (max-width: $desktop-screen) {
	  	right: -10%;
	  }
	}
  }
}
