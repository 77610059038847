@mixin vertical-align {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

 @mixin horizontal-align {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}

@mixin transform-center {
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

//Mixin for placeholder text
@mixin placeholder {
  $placeholders: ":-webkit-input" ":-moz" "-moz" "-ms-input";
  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder {
      @content;
    }
  }
}

/**
 * Force overly long spans of text to truncate, e.g.:
 *
   `@include truncate(100%);`
 *
 * Where `$truncation-boundary` is a united measurement.
 */
@mixin truncate-text($truncation-boundary: 100%){
    max-width:$truncation-boundary;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
}