.autocomplete-suggestions {
	color: $color-text;
    background-color: $color-brand-white;
    border-color: $grey-light;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0px 5px 7px -2px rgba(0, 0, 0, 0.4);
    //box-shadow: inset 0px 3px 5px -1px rgba(0, 0, 0, 0.1);
    display: hidden;
    overflow: auto;
    position: absolute;
    text-rendering: optimizeLegibility;
    width: 100%;
    //bottom: 0;
    left: 0;
    right: 0;
    top: 0;
}

.autocomplete-suggestion {
    @include truncate-text;

    cursor: pointer;
    padding: 0.25em 0.375em;

    > strong {
        color: $color-brand-blue;
        font-weight: $font-weight-semibold;
    }   
}

.autocomplete-selected {
    background-color: $grey-med;
}

.autocomplete-hint {
    color: tint($color-text, 40%);
    font-weight: $font-weight-semibold;
    opacity: 0.7;
    padding: 0.25em 0.375em;
}