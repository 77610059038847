.modal {
    display: none;
    position: absolute;
    z-index: 1000;
    left: 25%;
    top: 25%;
    width: 50%;
    height: auto;
    text-align: center;
    background-color: rgba(26, 26, 26, .9);

    .modal-content {
        color: white;
        padding: 0 60px 20px 60px;
        text-align: left;

        .header-text {
            @extend %hero-size;
            margin-top: 20px;
            margin-bottom: 10px;
        }

        .fields {
            margin: 0 0 20px 0;
        }
        
        .modal-col {
            width: 100%;
            @include media-min($tablet) {
                width: 48% !important;
            }
        }

        .modal-col-left {
            float: left;
        }

        .modal-col-right {
            float: right;
        }

        .field-row {
            clear: both;
        }

        .clear {
            clear: both;
        }

        input {
            margin: 10px 0 10px 0;
        }
    }
}

.validation-failed::-webkit-input-placeholder {
    color: red;
}
