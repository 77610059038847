.people-highlight {
	margin-bottom: 20px;
	line-height: 1.4;

	h2 {	
		text-transform: uppercase;
		color: $color-sidebar-colors;
		font-size: 1.2em;
		line-height: 1;
		margin-bottom: $spacing-unit/2;
	}

	img {
		border:1px solid $border-color;
	  	display: block;
	  	margin-bottom: $spacing-unit/2;
	}

	.name {
		font-size: 14px;
		font-weight: $font-weight-light;
	}

	.role {
		font-size: 12px;
	}
}