.people-results-filters {

	.active-filters > span {
		background-color: $grey-lightest;
	  	color:$grey-darkest;
		padding: 0 5px;
		border-radius: 5px;
	  	margin-right:10px;
	  	white-space: nowrap;
		display: inline-block;
		height: 25px;
	  	span{
		  top:-6px;
		  position: relative;
		}
	}

	.active-filters button {
		background-color: transparent;
		border: none;
		color: $blue;
	  	top:-2px;
	}
    .select-dropdown{
	  width:100%;
	  .select-container{
		@media(max-width:$tablet){
		  width:60%;
		}
		width:35%;
		position: relative;
	  }
	  select{
		width:100%;
	  }

	  .view-all{
		float:right;
		display: inline-block;
		span{
		  text-decoration: underline;
		  cursor:pointer;
		}
	  }
	}

	select{
	  height: 40px;
	  margin: 10px 0;
	  background-color: $grey-lightest;
	  font-weight:bold;
	  padding:0 10px;
	}


}