.searchblox-result {
    padding: 10px 0;
    font-size: 13px;

    .searchblox-result-title {
        font-size: 16px;
        height: 1.2em;
    }

    //attempt to match the nice title shortening that GSS did
    .searchblox-result-link {
        text-decoration: underline;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
    }

    .searchblox-result-subtitle {
        color: #008000;
        word-break: break-all;
        font-weight: bold;
        padding-bottom: 2px;
    }

    .searchblox-result-summary {
        font-weight: 500;
        color: #000;
        margin-top: 2px;
    }

    .searchblox-result-image{
        max-width: 50px;
        max-height: 50px
    }

    .searchblox-result-subcontainer{
        display: flex;
    }

}

.searchblox-pagination {
    margin-top: 20px;
}

.searchblox-page {
    font-size: 16px;
    margin-right: 8px;

    &.active {
        font-weight: bold;
        font-size: 18px;
    }
}