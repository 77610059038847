//
.grid-row {
	@extend %clearfix-micro;
}

[class*="grid-col-"] {
	float: left;
}

.grid-col-33 {
	width: 33.33333%;

	// @media all and (max-width: $desktop-screen) {
	// 	width: 48%;
	// }

	// @media all and (max-width: $phablet) {
	// 	width: 100%;
	// }

	.grid-row-spaced &{
		width: 32.33333%;
		margin-right: 1%;
	}
}

.grid-col-66 {
	width: 66.666667%;
}

.grid-col-50 {
	width: 50%;

	.grid-row-spaced &{
		width: 49%;

		&:nth-child(2n+1) {
			margin-right: 2%;
		}
	}
}

.grid-col-75 {
	width: 75%;
}

.grid-col-25 {
	width: 25%;
}

.grid-col-40 {
	width: 40%;
}

.grid-col-60 {
	width: 60%;
}


//table grid structure
.table-grid {
	display: table;
	width: 100%;
}

.table-grid-cell {
	display: table-cell;
	vertical-align: middle;
}