button {
	background-color: transparent;
	display: inline-block;
	padding: 0;
	border: 0 none;
	cursor: pointer;
	transition: $animation-basic;
}

.btn {
	display: inline-block;
	padding: 8px 30px;
	cursor: pointer;
}

.btn-default {
	@extend %uc;

	background-color: $bg-btn-default;
	color: $color-text;
	
}

.load-more {
	
}