.article-result {
	position: relative;
	padding-bottom: 10px;
    min-height: 127px;
	.result-img {
		float: left;
		width: 30%;
	  	line-height: 0;
		overflow: hidden;
		img {
			width: 100%;
		}
		div{
		  width:100%;
		  clear:both;
		  height:10px;
		}
	  @media all and (max-width: $desktop-screen) {
		transform: none;
		top: auto;
		position: relative;
	  }
	  @media all and (max-width: $phone-wide) {
		width: 50%;
		float:none;
		margin:0 auto;
	  }
	}

	.date{
	  color: $maroon;
	  font-size: 16px;
	  font-weight: 400;
	}

	.title {
		font-size: 16px;
		font-weight: 300;
		line-height: 1.2;
		margin-bottom: 10px;
	  	margin-top: 5px;
	}

	.content {
		line-height: 1.3;
	}

	.result-detail {
		float: left;
		display: inline-block;
		top: 47%;
		position: absolute;
		transform: translateY(-50%);
		width: 70%;
	  	padding:10px 10px 10px 20px;
	  	min-height: 115px;

	  	@media all and (max-width: $desktop-screen) {
	  		transform: none;
	  		top: auto;
	  		position: relative;
	  	}
		@media all and (max-width: $phone-wide) {
		  width: 100%;
		  float:none;
		  margin:0 auto;
		  display: block;
		}

	}
	&+.viewMore{
	  width:100%;
	  text-align:center;
	  font-size:26px;
	  color:$blue;
	  &:after{
		@extend %icon-style;
		content: "\e606";
		margin-left:20px;
		color: $color-brand-black;
	  }
	}
	&:nth-child(2n+1) {	
	  .result-detail{
		background-color: $grey-lightest;
	  }
		
	}
}
