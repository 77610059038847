*, *:before, *:after {
  box-sizing: border-box;
}


body.black {
	background-color: black;    
	color: white; 
}

p {
  margin: 0 0 $spacing-unit;  
}

ul {
	margin: 0 0 $spacing-unit;

	ul {
		margin-top: 0;
		margin-bottom: 0;
	}
	&+ul{
		margin-top:-$spacing-unit;
	}
}

img {
	max-width: 100%;
}

fieldset {
	border: 0 none;
	padding: 0;
}
.pdf-only{
  display:none;
}

#skip-to {
  height: 1px;
  width: 1px;
  clip: rect(0,0,0,0);
  overflow: hidden;
  position: absolute;
  top: 0;
  margin: -1px;

  &:focus {
    height: auto;
    width: auto;
    clip: auto;
    margin: 0;
    display: inline-block;
    overflow: visible;
    z-index: 500;
    background-color: $white;
    padding: .5em 1em;
  }
}