html {
    font-size: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
  font:	$font-weight-base $font-size-base $font-primary;
  color: $color-text;
  line-height: 2;
}

a {
  color: $color-links;
  text-decoration: none;
  outline: none;
  transition: $animation-basic;

  &:hover {
    color: $orange;
  }
}

// reset heading tags
h1, .h1-size,
h2, .h2-size,
h3, .h3-size,
h4, h5, h6 {
  font-weight: $font-weight-regular;
  text-rendering: optimizeLegibility; //Fix the character spacing for headings
  line-height: 1.1;
  margin: 0 0 $spacing-unit;
}

h1,.h1-size{
  font-size: 1.8em;
  line-height: 1.29;
}

h2,.h2-size{
  font-size: 1.5em;
  line-height: 1.2;
}

h3,.h3-size{
  font-size: 1.4em;
  line-height: 1.5
}

h4,.h4-size{
  font-size: 1.25em;
  line-height: 1.3;
}

h5,.h5-size{
  font-size: 1.1em;
}

h6,.h6-size{
  font-size: .95em;
  line-height: 1.43;
}

%small-text {
  font-size: .85em;
  line-height: 1.1;
}

%heading-alt {
    font-size: 1.2em;
    line-height: 1.2em;
}

.site-main-content {

  .hero-size-interior {
    font-size: 4em;
    font-weight: $font-weight-thin;
    line-height: 1.1;
    margin-top: 0;


    @include media-max($tablet) {
      font-size: 3em;
    }

    @include media-max($tablet-alt) {
      font-size: 2.2em;
    }
  }

}

%hero-size {
  font-size: 4.35em;
  font-weight: $font-weight-thin;
  line-height: 1.1;

  @include media-max($tablet) {
    font-size: 3em;
  }

  @include media-max($tablet-alt) {
    font-size: 2.2em;
  }
}

%giga-size {
  font-size: 3.5em;
  font-weight: $font-weight-thin;
  line-height: 1.1;

  @include media-max($tablet) {
    font-size: 2.5em;
  }

  @include media-max($tablet-alt) {
    font-size: 2em;
  }
}

%enormous-size {
  font-size: 7em;
  font-weight: $font-weight-thin;
  line-height: 1.1;

  @include media-max($tablet) {
    font-size: 2.5em;
  }

  @include media-max($tablet-alt) {
    font-size: 2em;
  }
}


b,
strong {
  font-weight: $font-weight-bold;
}

blockquote {
  color: $color-dark-blue;
  font-weight: $font-weight-bold;
  font-size: 1.2em;
  line-height: 1.4;
  margin: 0 0 $spacing-unit;
}
.black{
  blockquote {
    color: $color-brand-white;
    margin-left: 20px;
  }
}
.content-block-title {
  color:$color-dark-blue;

  .grid-content-sidebar & {
    font-size: 1.1em;
    color: $color-related-heading;
    font-weight: $font-weight-bold;
    margin-top: 0;
  }
}

.featured-title {
  font-size: 1.7em;
  font-weight: 300;
  color: $color-dark-blue;
  margin-bottom: 20px;
}

.sub-title {
  font-size: 1.85em;
  margin-bottom: 20px;
  font-weight: $font-weight-light;
  color: $maroon;
}

.landing-link {
  display: block;
  font-size: 2em;
  line-height: 1.3;
  font-weight: 300;
  border-bottom: 1px solid white;
  padding: 10px 0;
  text-transform: uppercase;

  &.active-link {
    color: $color-dark-blue;
  }

  &:hover {
    color: $yellow;
  }
  &.active{
	color: $yellow;
  }
  &:last-child {
   border-bottom: none;
  }
}

.disclaimer {
  color: $grey-med;
  font-weight: 300;
  margin-top: 20px;
}