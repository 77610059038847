.content-block{
  .list{
    margin-bottom:20px;
  }
  .content-block-title{
    margin-bottom:0;
    &.h4-size{
      color:$maroon;
    }
  }
}