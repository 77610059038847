.article {
	border: 1px solid $color-article-outline;
	background-color: rgba($white, .7);
	width: 30%;
	float: left;
	display: inline-block;
	margin-right: 5%;	  
	vertical-align: top;
  	height: 100%;
	&.mediakit {
		border: none;
	}
	
	@media all and (max-width: $tablet) {
		width: 100%;
		margin-bottom: 20px;
	    height:auto;
	}

	@media all and (max-width: $phone-wide) {
		width: 100%;
	}

	&:last-child {
		margin-right: 0;

		@media all and (max-width: $tablet) {
			margin-right: 4%;
		}
	}

	img {
		width: 100%;
	  	border-bottom:solid 1px $color-article-outline; 
	}

	.category {
		color: $color-dark-blue;
	  	text-transform: uppercase;
	}

	.article-container {
		padding: 20px;
	  	min-height:150px;		  
	}

	a.title {
		display: block;
		line-height: 1.25;
	  	font-size: 1.4em;
	    color:#00a0dc;
		& + .rich-text {
			margin-top: $spacing-unit/2;
		}

	}

	.date {
		color: $maroon;
	}

	.teaser {
		line-height: 1.5;
	}

	&.featured-article {
		min-height: 570px;
	}

	&.featured-article .title {
	  font-size: 32px;
	  line-height: 1.29;
	}

	&.featured-article .date {
	  font-size: 1.5em;
	  line-height: 1.2;
	}
	&.blog-category{
	  min-height: 0 !important;
		&.list{
			padding-bottom: 10px;
			float:none !important; 
		}
	  .article-container{
		min-height:80px;
	  }
	  .title{
		font-size:2em;
		font-weight:normal;
		margin-bottom:4px;
		color:$color-sidebar-colors;
		a{
		  color:$color-sidebar-colors;
		}
	  }
	  .lead{
		margin-bottom:4px;
	  }
	}
}

.featured-article,
.blog-category {
	width: 100%;
	margin-bottom: 20px;
}

.two-articles .article {
	width: 47.6%;
	margin-right: 4%;
	min-height: 450px;
	
	@media all and (max-width: $tablet) {
		width: 100%;
	}

	&:last-child {
		margin-right: 0;
	}
	
}

.three-articles,
.two-articles {
	margin-bottom: 50px; 
	clear: left;
  	display:table;
  	width: 100%;
  @media all and (max-width: $tablet) {
	margin-bottom: 0px;
  }
}

.featured-resources,.featured-achievements {
	clear: both;
	margin-top: $block-spacing;
    .three-articles{
	  .article {
		background-color: $opaquewhitecontent;
		float:left;
		.article-container{
		  min-height:270px;
		  @media (max-width: $wrapper-width) {
			min-height:340px;
		  }
		  @media (max-width: $tablet-landscape) {
			min-height:300px;
		  }
		  @media (max-width: $tablet) {
			min-height:200px;
		  }
		  @media (max-width: $tablet-alt) {
			min-height:250px;
		  }
		  //@media (max-width: $phablet) {
		  //min-height:360px;
		  //}
		}
	  }
	}
	.two-articles{
	  .article {
		background-color: $opaquewhitecontent;
		float:left;
		.article-container{
		  min-height:180px;
		  @media (max-width: $wrapper-width) {
			min-height:220px;
		  }
		  @media (max-width: $tablet-landscape) {
			min-height:250px;
		  }
		  @media (max-width: $tablet) {
			min-height:180px;
		  }
		  //@media (max-width: $tablet-alt) {
			//min-height:250px;
		  //}
		  //@media (max-width: $phablet) {
		  //min-height:360px;
		  //}
		}
	  }
	}
	.title {
		font-size: 1.2em;
	}
}
