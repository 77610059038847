.landing-page {
	position: relative;
	color: white;
	margin-top: 50px;
	min-height: calc(100% - 220px);

	.lead {
		
		font-weight: $font-weight-light;
	}

	.sub-heading {
		color: $yellow;
		margin-bottom: 10px;
		text-transform: uppercase;
	}

	.landing-content {
		margin-bottom: 10px;
	}

	.left-side {
		padding-right: 60px;
	}

	.right-side {
		padding-left: 60px;
	}

	@media all and (max-width: $tablet) {
		.left-side,
		.right-side {
			padding: 0;
			width: 100%;
		}
	}
}