﻿.news-results {
	& .viewMore{
	  width:100%;
	  text-align:center;
	  font-size:26px;
	  color:$blue;
	  &:after{
		@extend %icon-style;
		content: "\e606";
		margin-left:20px;
		color: $color-brand-black;
	  }
	}
    }