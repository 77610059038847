.professional-card {
	margin-bottom: 20px;
	margin-right: 4%;
	line-height: 24px;
	width: 188px;

	@include media-max($home-mobile) {
		margin-right: 0;
	}

	.singleton & {
		margin: 0;
		float: none;
	}

	.office{
	  a{
		font-size:1.3em;
	  }
	}

    .card{
	  .card-front{
		img{
		  width:auto;
		  margin:0 auto;
		}

		&.no-photo{
		  margin:0;
		  a{
			color:$color-brand-blue;
			i{
			  color:$color-brand-blue;  
			}
		  }
		}
	  }
	}

	.professional-cards-2x2 & {
		//&:nth-child(2n) {
		//	margin-right: 0;
		//}
        //
        //@media all and (max-width: $phone-wide) {
			//width: 300px;
			//margin-right: 0;
        //}
	} 

	.phone{
	  &:before{
		content:'P';
		margin-right:5px;
		font-weight:bold;
	  }
	}

	.connect {
	  	margin-top:15px;
		font-size: 2.2em;
	}
	
	.name {
		font-size: 1.3em;
		color: $blue;
		font-weight: 300;
		line-height: 1;
		margin-top: 10px;
	}

	.role {
		color: gray;
		font-weight: 300;
	    min-height:24px;
	}
	
	a {
		color: white;
	}

	.card .container {
		height: 200px;
	}	
}

.related-professionals-container {
	display: flex;
	flex-direction: column;

	.news-related-professionals{
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		width: 74%;
	
		@include media-max($home-mobile) {
			flex-wrap: nowrap;
			flex-direction: column;
		}
	}
}