.site-hero-content {
	background-size: auto 100%;
	padding: 3.5em 0;
	color: $color-brand-white;

	@media all and (max-height: 750px) {
		height: auto;

		background-size: 100% auto;
	}

	@include media-max($phone-wide) {
		height: auto;
		background-size: 100% auto;
	}

	.grid-content-main {
		margin-right: 4%;
	}

	.highlight-item {
		margin-left: 0;
	}
}

.site-hero-section {
	margin-top: 0;
	line-height: 1.2;
}

.site-hero-title {
	@extend %hero-size;

	margin-top: 0;
	margin-bottom: 10px;
	text-transform: lowercase;

	em,
	span {
		font-style: normal;
		font-weight: $font-weight-thin;
	}

	span.blue {
		color: $color-brand-blue;
	}

	span.black {
		color: $black;
	}

	span.white {
		color: $white;
	}
}