.content-wrap-full {
	@include media-max( $wrapper-width ) {
		width: 100%;
		padding: 0 $site-content-padding;
	}

	@include media-max( $tablet-alt ) {
		width: 100%;
		padding: 0 $site-content-padding-md;
	}
}

.content-wrap-center {
	max-width: 642px;
	margin: 0 auto;
}

.content-wrap-right {
	padding-right: 15%;

	@include media-max( $wrapper-width ) {
		padding-left: $site-content-padding;
	}

	@include media-max( $tablet-alt ) {
		padding-left: $site-content-padding-md;
	}

	@include media-max($phone-wide) {
		padding: 0 5%;
	}
}

.content-wrap-left {
	padding-left: 20%;

	@include media-max( $wrapper-width ) {
		padding-left: $site-content-padding;
	}

	@include media-max( $tablet-alt ) {
		padding-left: $site-content-padding-md;
	}

	@include media-max($phone-wide) {
		padding: 0 5%;
	}
}

.content-wrap-right,
.content-wrap-left,
.content-wrap-full {
	width: 1195px;
	margin: 0 auto;

	@include media-max( $wrapper-width ) {
		width: 100%;
	}

	@include media-max( $tablet) {
		padding: 0 $site-content-padding;
	}

	@include media-max( $tablet-alt ) {
		padding:0 $site-content-padding-md;
	}

	@include media-max($phone-wide) {
		padding: 0 5%;
	}
}

.grid-content-large {
	min-height: 1px;
	width: 70%;
}

.grid-content-large-75 {
	min-height: 1px;
	width: 75%;
}

.grid-content-med {
	min-height: 1px;
	width: 40%;
}

.grid-content-small {
	min-height: 1px;
	width: 30%;
    @include media-max( $tablet-landscape){
	  .highlight-item{
		min-height: 100px !important;
		.rich-text{
		  width: 120px;
		  min-height: 100px;
		  margin-bottom: 0; 
		}
	  }
    }
  @include media-max( $phone-wide){
	.highlight-item{
	  .rich-text{
		width: 200px;
	  }
	}
  }

}

.grid-content-med-half {
	min-height: 1px;
	width: 50%;
}

.grid-content-small-quarter {
	min-height: 1px;
	width: 25%;
}

.grid-content-large,
.grid-content-large-75,
.grid-content-med,
.grid-content-med-half,
.grid-content-small,
.grid-content-small-quarter {
	@include media-max( $phone-wide){
		width: 100%;
	}
}