.alpha-list-filter {
	overflow: hidden;
	margin-bottom: 20px;

	@media all and (max-width: $phone-wide) {
		width: auto;	
	}
}

.alpha-list-link {
	@extend %giga-size;
	@extend %uc;
	font-weight: 300;
	display: block;
	float: left;
	width: 7.69231%;
	margin-top: 15px;

}

.alpha-list-link {
	color: $color-brand-yellow;

	&:hover {
		color: $color-links;
	}
	&.active{
	  color: $color-links;
	}
	&.is-disabled {
		color: $color-disabled;

		&:hover {
			color: $color-disabled;
		}
	}
}