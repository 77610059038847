.news-detail-sidebar {
	h2 {	
		text-transform: uppercase;
		
		color: $color-sidebar-colors;
		font-size: 1.2em;

	}
	.sidebar-section{
	  margin-bottom:20px;
	}
	.people-highlight {
		margin-bottom: 20px;
		line-height: 1.4;

		img {
			border:1px solid $border-color;
		}

		.name {
			font-size: 1.1em;
		}

		.role {
			font-size: 1.05em;
		}
	}
}