.accordion-component {
	overflow: hidden;

	.accordion-title {
		color: $blue;
		cursor: pointer;
		font-size: 1.3em;
	  	line-height: 24px;
	  	margin-bottom:10px;
	}

	.open {
		display: none;
	  	color:$color-brand-black;
	}

	.closed {
		display: inline;
	  color:$color-brand-black;
	}
	.toggle-state {
		margin-left:5px;
	}
	&.industry-accordion {
		padding-right: 30px;

		 span.trigger{
		   color:$color-brand-white;
		   padding-left:4px;
		 }

		.toggle-state {
			color: $color-brand-white;
            cursor: pointer;

			.open,
			.closed {
				color: $color-brand-white;
			}
		}

		.accordion-content {
			display: block;
			padding-left: 15px;
			div{
			  line-height: 16px;
			  margin-bottom:6px;
			}
			a {
				color: $color-grey-text;

				&:hover {
					color: $color-brand-yellow;
				}
			}
		}
	}

	&.active-accordion {
		.open {
			display: inline;
		}
		.closed {
			display: none;
		}
	}

	&.blog-accordion {

		.accordion-title {
			text-align: center;
			font-size: 22px;
		  	color: #00a0dc;
		}

		.toggle-state {
			font-size: 1.5em;
			color: $color-brand-white;
		}

		.blog-accordion-post {
			border-bottom: 1px solid black;
			padding: 20px 20px 20px 0;
		  	line-height: 2em;
		}

		.blog-accordion-post:last-child {
			border-bottom: none;
		}
	}

	.accordion-content {
		display: none;
	}
}
.accordion-expand-all {
    display: inline-block;
    margin-left: 25px;
	color: $blue;
	cursor: pointer;
	font-size: 1.3em;
	span.trigger{
	    color:$color-brand-white;
	    padding-left:4px;
	}
}