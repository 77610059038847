.person-result {
	position: relative;
	background-color: #085087;
	overflow: hidden;
	padding: 20px;
	min-height:171px;
  	.image{
	  width: 30%;
	  display:inline-block;
	  float: left;
	}
  	.person-image{
	  background-color:white;
	  padding: 0;
	}
	.person-image,.no-photo {
	  width: 70%;
	}
	.no-photo{
	  padding-top:20px;
	  text-align: center;
	}
	.person-info {
		float: left;
		width: 45%;
		font-weight: 300;

		.name {
			font-size: 1.3em;
			color: $white;
		}

		.role {
			color: $blue-baby;
		}

		.office a {
			color: $orange;
			padding-right: 5px;
			margin-right: 5px;
			border-right: 1px solid white;
		}
	}

	.contact-icons {
		text-align: center;
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		width: 20%;
		font-size: 2.7em;

		a {
			color: white;
		}
	}
	&+.viewMore{
	  width:100%;
	  text-align:center;
	  font-size:26px;
	  color:$yellow;
	  padding:15px 0;
	  background-color: rgba($color-dark-blue, .6);
	  &:after{
		@extend %icon-style;
		content: "\e606";
		margin-left:20px;
		color: $blue-baby;
	  }
	}
	.bottom-border {
		height: 1px;
		width: calc(100% - 40px);
		position: absolute;
		bottom: 0;
		background-color: #fff;
	}

	&:last-child .bottom-border {
		display: none;
	}

}