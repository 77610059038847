.site-search-controls {
	margin-bottom: 20px;

	.filter-button {
		background-color: $grey-med;
		padding: 15px;
		margin-bottom: 10px;
	}

	.search-box {
		position: relative;
		max-width: 600px;
		margin-bottom: 10px;
	}

	.search-input {
		height: 50px;
	}

	.search-button {
		background-color: $blue-baby;
		top: 0;
		right: 0;
		padding: 15px;
	}
	
	.search-filters {
		padding: 10px 0;
	}

	input[type=radio] {
		display: none;
	}

	input[type=radio]:checked + .filter-button {
		background-color: $blue-baby;
		color: #000;
	}
}