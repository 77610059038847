.location-card {
	border: 1px solid white;
  	height:220px;
	width: 24%;
	margin-right: 1.33%;
	margin-bottom: 20px;
	padding: 20px;
	float: left;
  	background-size: cover;
	
	&:nth-child(4n) {
		margin-right: 0; 
	}

	.city-name {
		text-transform: uppercase;
		color: $orange;
		font-size: 1.2em;
	}

	> div {
		line-height: 1;
		margin-bottom: 10px;
	}

	a:hover {
		color: $orange;
	}

	@include media-max($tablet-alt) {
	  width: 48%;
	  height:240px;
	}
}
