form{
  .form-input{
    width:50%;
    float:left;
    margin-bottom:10px;
    input[type=text],input[type=email], textarea{
      background-color: $grey-lightest;
      border:$grey-light;
      width:94%;
    }
    select{
      background-color: $grey-lightest;
      border:$grey-light;
      width:94%;
      padding: 15px 15px 13px;
      font-weight: 500;
    }
    .warning-label{
      color:$maroon;
    }
    &.large{
      width:100%;
      textarea{
        width:97%;
      }
    }
  }
  .checkbox-container{
    input[type=checkbox]{
      float:left;
      margin: 6px 10px 0 0 ;
      width:20px;
      display:inline-block;
    }
  }
  input[type=submit]{
    padding: 12px 20px;
    background: #C8E4F1;
    font-weight: bold;
    display: inline-block;
    width:auto;
  }
}

.error-message,
.success-message {
    display: none;
}

.error-message {
    color: red;
    margin-top: 16px;

    p {
        margin: 0;
    }

    &--resource-landing {
        background-color: #AD0000;
        color: white;
        text-align: center;
    }
}